<template>
  <div class="">
    <b-card-actions class="card-infos mb-1" title="Informações da turma" action-collapse>
      <b-card-text>
        <div class="form-row">
          <div class="col-md-2">
            <div class="form-group">
              <strong>Código: </strong>
              {{ item.name }}
            </div>
          </div>
          <div class="col-md-7">
            <div class="form-group">
              <strong>Título: </strong>
              {{ item.course.title }}
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <strong>Turno: </strong>
              {{ item.shift.title }}
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <strong>Modalidade:</strong>
              {{ item.modality.title }}
            </div>
          </div>
          <div v-if="item.start_time" class="col-md-3">
            <div class="form-group">
              <strong>Horário:</strong>
              {{ item.start_time }} às
              {{ item.end_time }}
            </div>
          </div>
          <div v-if="item.unit" class="col-md-3">
            <div class="form-group">
              <strong>Unidade:</strong>
              {{ item.unit.title }}
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group" v-if="this.item.postponements.length > 0">
              <strong>Início:</strong>
              {{ item.postponements[item.postponements.length - 1].date | datePT }}
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group mb-md-0">
              <strong>Situação:</strong>
              {{ item.status.title }}
            </div>
          </div>
          <div class="col-md-2">
            <div class="form-group mb-md-0">
              <strong>P. vendas:</strong>
              {{ item.display_panel ? "Sim" : "Não" }}
            </div>
          </div>
          <div class="col-md-8" v-if="item.weekdays.length">
            <div class="form-group mb-0 mb-md-0">
              <ul class="list-inline mb-0">
                <li><strong class="d-block mr-1">Dias de aula:
                  </strong></li>
                <li v-for="(element, index) in item.weekdays" :key="index">
                  <b-badge pill variant="light-primary" style="margin-right: 4px;">{{
    element.title }}</b-badge>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </b-card-text>
    </b-card-actions>

    <div class="card mb-1">
      <div class="card-header mb-0">Disciplinas</div>
      <b-table responsive :items="tableDisciplines" :fields="tableFields" class="mb-0">
        <template #cell(lessons)="{ item }">
          {{ item.lessons }}
        </template>
        <template #cell(category_1)="{ item }">
            {{ item.category_1 ? item.category_1 : '-' }}
          </template>
          <template #cell(category_2)="{ item }">
            {{ item.category_2 ? item.category_2 : '-' }}
          </template>
          <template #cell(category_3)="{ item }">
            {{ item.category_3 ? item.category_3 : '-' }}
          </template>
        <template #cell(title)="{ item }">
          <div class="d-flex-full">
            <span class="text-nowrap">
              {{ item.title }}
            </span>
          </div>
        </template>
        <template #cell(workload)="data">
          <span class="text-nowrap">
            {{ data.value }}
          </span>
        </template>
        <template #cell()="data">
          {{ checkField(data.field, data.item) }}
        </template>
      </b-table>
    </div>

    <div class="form-row">
      <div class="col-md-4">
        <b-card :class="{ show: isCalendarOverlaySidebarActive }" class="mb-1">
          <h5 class="text-purple">
            <strong>Datas seleciondas</strong>
            <span class="badge badge-pill badge-primary float-right">
              {{ $store.state.Grid.days.length }}
            </span>
          </h5>
          <hr />
          <small class="d-block" :class="index !== $store.state.Grid.days.length - 1 ? 'mb-1' : ''"
            v-for="(day, index) in $store.state.Grid.days" :key="index">
            <i class="bi bi-calendar2-event" style="margin-right: 3px"></i>
            {{ index + 1 }}/{{ $store.state.Grid.days.length }} -
            {{ day }}
          </small>
          <p class="mb-0" v-if="!$store.state.Grid.days.length">
            Nenhuma data selecionada.
          </p>
        </b-card>
      </div>

      <div class="col-md-8">
        <div class="app-calendar calendar-grid overflow-hidden border">
          <div class="row no-gutters">
            <div class="col position-relative">
              <div class="card shadow-none border-0 mb-0 rounded-0 card-header-custom-actions">
                <div class="card-header">
                  <div class="form-row justify-content-between">
                    <div class="col-md-10">
                      <p class="mb-0 font-small-3">
                        Selecione pelo menos 1 data no calendário baixo, caso queira
                        <strong class="text-black">criar(não importar)</strong> uma
                        aula.
                      </p>
                    </div>
                    <div class="col-md-2">
                      <b-dropdown v-ripple.400="'rgba(255, 255, 255, 0.15)'" text="Opções" variant="primary" right block>
                        <b-dropdown-item @click="isModalGridLessonImportActive = true">
                          <i class="bi bi-arrow-down-up"></i>
                          Importar grade
                        </b-dropdown-item>

                        <b-dropdown-item :disabled="!formLesson.days.length" @click="isEventHandlerSidebarActive = true">
                          <i class="bi bi-plus-lg"></i>
                          Criar aula
                        </b-dropdown-item>

                        <b-dropdown-item>
                          <i class="bi bi-send"></i>
                          Cobrar professor
                        </b-dropdown-item>
                        <b-dropdown-divider />
                        <b-dropdown-item class="text-danger">
                          <i class="bi bi-x-lg"></i>
                          Encerrar turma
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </div>
                </div>
                <div class="card-body pb-0">
                  <full-calendar ref="refCalendar" :options="calendarOptions" class="full-calendar" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal id="modal-new-lesson" centered title="Cadastrar aula " ok-only ok-title="Cadastrar" ok-variant="success"
      no-close-on-backdrop no-close-on-esc hide-header-close @hide="onHide" :visible="isEventHandlerSidebarActive"
      hide-footer class="modal-new-lesson">
      <b-card-text>
        <b-form @submit.prevent="submitNewLesson">
          <div v-if="disciplineSelected" class="form-row justify-content-between">
            <div class="col-md-8">
              <h4 class="text-primary" style="margin-bottom: 5px">
                {{ disciplineSelected.title }}
              </h4>
              <strong class="d-block">
                Aulas:
                {{ disciplineSelected.days }}
              </strong>
            </div>
            <div class="col-md-4 text-right">
              <span class="d-block" style="margin-bottom: 5px">Carga horária
              </span>
              <strong class="d-block">
                {{ disciplineSelected.workload }}h
              </strong>
            </div>
          </div>
          <div class="d-block mb-2">
            <hr />
            <div class="form-row form-group">
              <label class="col-md-3 col-form-label">
                <i class="text-danger bi bi-record-circle"></i>
                Disciplina
              </label>
              <div class="col">
                <v-select :disabled="!days.length" :searchable="false" label="title" v-model="disciplineSelected"
                  item-text="title" item-value="code" placeholder="Digite o título" :options="item.disciplines"
                  @input="changeDiscipline(disciplineSelected)">
                  <span slot="no-options"> Nenhum registro encontrado </span>
                </v-select>
              </div>
            </div>

            <div class="d-block">
              <div class="form-row form-group">
                <label class="col-md-3 col-form-label">
                  <i class="text-danger bi bi-record-circle"></i>
                  Professor
                </label>
                <div class="col">
                  <v-select :searchable="true" label="title" v-model="formLesson.teacher" :class="{
                    'is-invalid': $v.formLesson.teacher.$error,
                  }" item-text="title" item-value="code" placeholder="Digite o título" :options="optionsTeachers">
                    <span slot="no-options">
                      Nenhum registro encontrado
                    </span>
                  </v-select>
                </div>
              </div>

              <div class="form-row form-group">
                <label class="col-md-3 col-form-label"> Materiais </label>
                <div class="col">
                  <v-select multiple label="title" item-text="title" item-value="code" v-model="formLesson.materials"
                    placeholder="Digite o título do material" :options="optionsMaterials" @search="fetchMaterials">
                    <span slot="no-options">
                      Nenhum registro encontrado
                    </span>
                  </v-select>
                </div>
              </div>

              <div class="form-row form-group mb-0">
                <label class="col-md-3 col-form-label">
                  <i class="text-danger bi bi-record-circle"></i>
                  Status
                </label>
                <div class="col">
                  <v-select :searchable="false" label="title" v-model="formLesson.status" :class="{
                    'is-invalid': $v.formLesson.status.$error,
                  }" item-text="title" item-value="code" placeholder="Digite o título"
                    :options="optionsGridLessonStatus">
                    <span slot="no-options">
                      Nenhum registro encontrado
                    </span>
                  </v-select>
                </div>
              </div>
            </div>
          </div>

          <hr />
          <div class="form-row">
            <div class="col-md-11">
              <h5 class="mb-0">
                Datas: 
                <span class="badge badge-pill badge-primary">
                  {{ formLesson.days.length }}
                </span>
                  
              </h5>
            </div>
            <div class="col-md-1">
              <button type="button" @click="addDay" class="btn btn-block btn-primary" style="padding: 5px 3px !important">
                <i class="bi bi-plus"></i>
              </button>
            </div>
          </div>                  
          <hr />

          <div v-if="formLesson.days.length" class="form-row mb-1" v-for="(day, index) in formLesson.days"
            :key="index" style="align-items: center;">
            <div class="col-md-1"
              style="align-content: center; justify-content: left; align-items: center; column-gap: 20px;">
              {{ formLesson.days[index].number }}/{{ days.length }}
            </div>

            <div class="col-md-3" :class="{
                'is-invalid': $v.formLesson.days.$each[index].date.$error,
              }">
              <flat-pickr v-model="day.date" class="form-control" :config="{
                altInput: true,
                dateFormat: 'd/m/Y',
                altFormat: 'd/m/Y',
                locale: 'pt',
              }" />
            </div>

            <div class="col-md-4">
              <div class="d-flex" style="align-items: center;">
                <input type="tel" :class="{
                  'is-invalid': $v.formLesson.days.$each[index].start.$error,
                }" placeholder="H. Início" v-model="day.start" class="form-control mr-1" v-mask="['##:##']" />
                <input type="tel" :class="{
                  'is-invalid': $v.formLesson.days.$each[index].end.$error,
                }" placeholder="H. Fim" v-model="day.end" class="form-control" v-mask="['##:##']" />
              </div>
            </div>

            <div class="col-md-4">
              <v-select :searchable="false" label="title" v-model="day.category" :class="{
                'is-invalid': $v.formLesson.days.$each[index].category.$error,
              }" item-text="title" item-value="code" placeholder="Digite o título"
                :options="optionsGridLessonCategory">
                <span slot="no-options">
                  Nenhum registro encontrado
                </span>
              </v-select>
            </div>
          </div>

          <hr />

          <div class="form-row justify-content-end">
            <div class="col-md-3">
              <button @click="closeModal" type="button" class="btn btn-light btn-block">
                Fechar
              </button>
            </div>
            <div class="col-md-3">
              <button type="submit" class="btn btn-success btn-block">
                Cadastrar
              </button>
            </div>
          </div>
        </b-form>
      </b-card-text>
    </b-modal>

    <b-modal id="modal-import-grid" centered title="Importar grade " ok-only ok-title="Importar" ok-variant="success"
      no-close-on-backdrop no-close-on-esc hide-header-close @hide="onHide" :visible="isModalGridLessonImportActive"
      hide-footer>
      <b-card-text>
        <form @submit.prevent="submitLessonImports">
          <div class="d-block mb-2">
            <div class="form-row">
              <div class="col-12">
                <div class="form-group">
                  <label>
                    <i class="text-danger bi bi-record-circle"></i>
                    Qual grade deseja importar?
                  </label>
                  <v-select label="title" item-text="title" item-value="code" v-model="formImportLessons.grid"
                    placeholder="Digite o título" @search="fetchGrid" :options="optionsGrids" @input="gridSelected" />
                </div>
              </div>

              <div v-if="optionsDisciplines.length" class="col-12">
                <div class="form-group">
                  <label>
                    <i class="text-danger bi bi-record-circle"></i>
                    Selecione 1 ou mais disciplinas
                  </label>
                  <b-list-group>
                    <b-list-group-item v-for="(discipline, index) in optionsDisciplines" :key="index">
                      <div class="d-flex">
                        <b-form-checkbox :checked="true" :value="discipline.code" class="custom-control-primary"
                          :name="`discipline-${index}`" :id="`discipline-${index}`" switch
                          v-model="formImportLessons.disciplines">
                        </b-form-checkbox>
                        <span>
                          {{ discipline.title }} <small>({{ discipline.lessons }})</small>
                        </span>
                      </div>
                    </b-list-group-item>
                  </b-list-group>
                </div>
              </div>

              <div class="col-12">
                <div class="form-group">
                  <label>
                    Deseja importar a partir de alguma data?
                  </label>
                  <flat-pickr v-model="formImportLessons.date" class="form-control" :config="{
                    dateFormat: 'd/m/Y',
                    altFormat: 'Y-m-d',
                    locale: 'pt',
                    enableTime: false,
                    wrap: true,
                  }" />
                </div>
                <small><i class="bi bi-info-circle"></i> Se nenhuma data for selecionada, as aulas programadas para ocorrer a partir de hoje serão importadas.</small>
              </div>
            </div>
          </div>

          <hr />

          <div class="form-row justify-content-end">
            <div class="col-md-3">
              <button @click="isModalGridLessonImportActive = false" type="button" class="btn btn-light btn-block">
                Fechar
              </button>
            </div>
            <div class="col-md-3">
              <button :disabled="submitedImport" type="submit" class="btn btn-success btn-block">
                <span v-if="submitedImport">
                  <b-spinner small variant="light" />
                </span>
                <span> Importar </span>
              </button>
            </div>
          </div>
        </form>
      </b-card-text>
    </b-modal>
  </div>      
</template>

<script>
import {
  BFormInput,
  BInputGroupAppend,
  BInputGroupPrepend,
  BInputGroup,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BCardText,
  BCard,
  BCardFooter,
  BCardHeader,
  VBTooltip,
  BFormCheckbox,
  VBToggle,
  BButton,
  BModal,
  BSpinner,
  BListGroup,
  BListGroupItem,
  BTooltip,
  BBadge,
  BDropdown,
  BDropdownDivider,
  BDropdownItem,
  BAlert,
  BTable
} from "bootstrap-vue";

import GridLessonImport from "@/components/GridLessonImport";
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'

import vSelect from "vue-select";
import { required, url, requiredIf, minLength } from "vuelidate/lib/validators";
import draggable from "vuedraggable";
import Ripple from "vue-ripple-directive";
import CustomPaginateTable from "@/views/components/custom/PaginationTable";
import ButtonsActionsFooter from "@/views/components/button/ButtonsActionsFooter";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/l10n/pt.js";

import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";

import FullCalendar from "@fullcalendar/vue";
import useCalendar from "./useCalendar";

import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";

import CalendarSidebar from "./calendar-sidebar/CalendarSidebar.vue";
import CalendarEventHandler from "./calendar-event-handler/CalendarEventHandler.vue";

import { mapState } from "vuex";
import moment from "moment";

export default {
  components: {
    flatPickr,
    FullCalendar,
    CalendarSidebar,
    GridLessonImport,
    CalendarEventHandler,
    CustomPaginateTable,
    ButtonsActionsFooter,
    BSpinner,
    BModal,
    BTable,
    BFormCheckbox,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    AppTimeline,
    AppTimelineItem,
    BCardActions,
    BCard,
    BCardHeader,
    BCardText,
    BCardFooter,
    draggable,
    BFormInput,
    BFormGroup,
    vSelect,
    BForm,
    BRow,
    BCol,
    BButton,
    BListGroup,
    BListGroupItem,
    BTooltip,
    VBTooltip,
    AppCollapse,
    AppCollapseItem,
    BBadge,
    BDropdown,
    BDropdownDivider,
    BDropdownItem,
    BAlert,
  },
  data() {
    return {
      formImportLessons: {
        grid: "",
        disciplines: [],
        date: ""
      },
      optionsGrids: [],
      isModalGridLessonImportActive: false,
      submitedFind: false,
      dataItems: [],
      nextIdPostponements: 0,
      nextIdWorkDays: 0,
      submited: false,
      submitedImport: false,
      disciplineSelected: "",
      classroom_uuid: "",
      item: {
        uuid: "",
        name: "",
        shift: "",
        modality: "",
        course: "",
        max_students: "",
        date: "",
        start_time: "",
        end_time: "",
        display_panel: false,
        unit: "",
        status: { title: "Ativo", code: 1 },
        disciplines: "",
        postponements: "",
        weekdays: "",
        lessons: "",
      },
      filters: {
        tag_subject: [],
        title: "",
      },
      modalShow: false,
      optionsModalities: [],
      optionsCourses: [],
      optionsUnits: [],
      optionsShifts: [],
      optionsWorkDays: [],
      optionsTeachers: [],
      optionsDisciplines: [],
      optionsGridLessonCategory: [],
      formLesson: {
        teacher: "",
        status: "",
        days: [],
        materials: [],
      },
      optionsGridLessonStatus: [],
      optionsMaterials: [],
      tableDisciplines: [],
      tableFields: [
        {
          key: "lessons",
          label: "Aulas",
          tdClass: "text-center",
          class: "text-center",
          thStyle: "width: 80px",
        },
        {
          key: "title",
          label: "Disciplina",
          tdClass: "text-left",
          class: "text-left",
        },
        {
          key: "workload",
          label: "Previsto",
          tdClass: "text-center",
          class: "text-center",
        },
        {
          key: "category_1",
          label: "Normal",
          tdClass: "text-center",
          class: "text-center",
        },
        {
          key: "category_2",
          label: "Bônus",
          tdClass: "text-center",
          class: "text-center",
        },
        {
          key: "category_3",
          label: "Extra",
          tdClass: "text-center",
          class: "text-center",
        },
      ],
    };
  },
  setup() {
    const {
      isCalendarOverlaySidebarActive,
      calendarOptions,

      // ----- UI ----- //
      isEventHandlerSidebarActive,
      isEventHandlerEditLesson,
    } = useCalendar();

    return {
      isCalendarOverlaySidebarActive,
      calendarOptions,

      // ----- UI ----- //
      isEventHandlerSidebarActive,
      isEventHandlerEditLesson,
    };
  },
  validations: {
    formImportLessons: {
      disciplines: {
        required,
      },
      grid: {
        required,
      }
    },
    formLesson: {
      teacher: {
        required,
      },
      status: {
        required,
      },
      days: {
        $each: {
          date: {
            required,
          },
          start: {
            required,
            minLength: minLength(5)
          },
          end: {
            required,
            minLength: minLength(5)
          },
          category: {
            required,
          },
        },
      },
    },
  },
  methods: {
    addDay() {
      const date = moment().format('DD/MM/YYYY');

      this.days.push(date)
    },
    submitLessonImports() {
      this.submitedImport = true;
      this.$v.formImportLessons.$touch();

      if (!this.$v.formImportLessons.$error) {
        this.$store
          .dispatch("Grid/storeImport", {
            grid_uuid: this.formImportLessons.grid.code,
            classroom_uuid: this.classroom_uuid,
            disciplines: this.formImportLessons.disciplines,
            date: this.formImportLessons.date,
          })
          .then((uuid) => {
            this.notifyDefault("success");
            this.$router.push({ name: "grids-edit", params: { uuid: uuid } });
          })
          .catch(() => {
            this.submitedImport = false;
            this.notifyDefault("error");
          });
      } else {
        this.notifyDefault("error", "Verifique os campos obrigatórios");
      }
    },
    disciplinesSelected(event) {
      this.formImportLessons.disciplines = event.map(
        (discipline) => discipline.discipline_workload_uuid
      );
    },
    gridSelected(event) {
      this.optionsDisciplines = [];
      this.formImportLessons.disciplines = [];

      if (event.disciplines) {
        this.optionsDisciplines = event.disciplines;
        delete this.formImportLessons.grid.disciplines;
      }
    },
    async fetchGrid(term) {
      if (term.length > 1) {
        this.optionsGrids = await this.$store.dispatch("Grid/search", term);
      }
    },
    async changeDiscipline(value) {
      const _days = this.days;

      value.days = _days.length;
      await this.$store.dispatch("Grid/setDiscipline", value);
    },
    changeTime() {
      let duration = 0;

      const start = moment(this.item.start_time, "HH:mm");
      const end = moment(this.item.end_time, "HH:mm");

      const minutes = end.diff(start, "minutes") * this.formLesson.days.length;

      const hours = moment.utc().startOf("day").add(minutes, "minutes");
      const _workload = hours.format("HH:mm");

      this.formLesson.workload = _workload;

      this.formLesson.days.forEach((element) => {
        if (element.start && element.end) {
          const moment1 = moment(element.start, "HH:mm");
          const moment2 = moment(element.end, "HH:mm");
          duration = duration + moment2.diff(moment1, "hours");
          element.start = start;
          element.end = end;
        }
      });

      let workload = `${this.disciplineSelected.workload > 9
        ? this.disciplineSelected.workload
        : "0" + this.disciplineSelected.workload
        }:00`;

      workload = moment(workload, "HH:mm").format("HH:mm");

      const hora1 = moment(workload, "HH:mm");
      const hora2 = moment(duration, "HH:mm");
      const diffHoras = hora1.diff(hora2, "hours");

      this.$store.dispatch("Grid/setDiscipline", {
        ...this.disciplineSelected,
        workloadCurrent: diffHoras,
        days: this.days.length,
      });
    },
    submitNewLesson() {
      this.$v.formLesson.$touch();

      if (!this.$v.formLesson.$error) {
        this.$store
          .dispatch("Grid/store", {
            classroom_uuid: this.classroom_uuid,
            discipline: this.disciplineSelected,
            lessons: this.formLesson,
          })
          .then((uuid) => {
            this.notifyDefault("success");
            this.$router.push({ name: "grids-edit", params: { uuid: uuid } });
          })
          .catch(() => {
            this.notifyDefault("error");
          })
          .finally(() => {
            this.submited = false;
          });
      } else {
        this.notifyDefault("error", "Verifique os campos obrigatórios");
      }
    },
    resetModal() {
      this.name = "";
      this.nameState = null;
    },
    handleOk(bvModalEvent) {
      bvModalEvent.preventDefault();

      this.$v.formLesson.$touch();

      if (!this.$v.formLesson.$error) {
        this.handleSubmit();
      } else {
        this.notifyDefault("error", "Verifique os campos obrigatórios");
      }
    },
    async handleSubmit() {
      await this.$store
        .dispatch("Grid/store", { disciplineUuid: this.lesson })
        .then(() => {
          this.$nextTick(() => {
            this.$bvModal.hide("modal-new-lesson");
          });
        });
    },
    closeModal() {
      this.$bvModal.hide("modal-new-lesson");
      this.isEventHandlerSidebarActive = false;
    },
    onHide(evt) {
      if (evt.trigger === "backdrop") {
        evt.preventDefault();
        this.isEventHandlerSidebarActive = false;
      }
    },
    async fetchTeachers() {
      this.optionsTeachers = [];
      this.optionsTeachers = await this.$store.dispatch(
        "Teacher/getByTagsSubjects",
        { disciplineUuid: this.disciplineSelected.code }
      );
    },
    async fetchMaterials(term) {
      if (term.length > 2) {
        this.optionsMaterials = await this.$store.dispatch(
          "Material/forSelectSearchTypeUnits",
          { term: term, type: 1 }
        );
      }
    },
    async fetchCourse(term) {
      this.optionsCourses = await this.$store.dispatch("Product/forSelect", {
        term: term,
        course: 1,
      });
    },
    async updateDays() {
      let datas = this.days;

      const datasISO = datas.map((data) =>
        moment(data, "DD/MM/YYYY").toISOString()
      );

      function compararDatas(a, b) {
        const dataA = moment(a);
        const dataB = moment(b);
        if (dataA.isBefore(dataB)) {
          return -1;
        }
        if (dataA.isAfter(dataB)) {
          return 1;
        }
        return 0;
      }

      datasISO.sort(compararDatas);

      const datasOrdenadas = datasISO.map((dataISO) =>
        moment(dataISO).format("DD/MM/YYYY")
      );

      await this.$store.dispatch("Grid/updateDays", datasOrdenadas);
    },
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  async mounted() {
    this.optionsGridLessonStatus = await this.$store.dispatch(
      "Grid/forSelectLessonStatus"
    );
    this.optionsGridLessonCategory = await this.$store.dispatch(
      "Grid/forSelectLessonCategories"
    );

    this.classroom_uuid = this.$route.params.uuid;

    this.$root.$on("bv::modal::hide", (bvEvent, modalId) => {
      if (bvEvent.type === "hide" && modalId === "modal-new-lesson") {
        this.isEventHandlerSidebarActive = false;
      }
    });

    this.$root.$on("GridLessonNew", (value) => {
      console.log(value);
    });

    await this.$store
      .dispatch("Grid/create", this.classroom_uuid)
      .then(async (data) => {
        this.item = data;

        if (this.item.formLesson && this.item.formLesson.length > 0) {
          await this.$store.dispatch("Grid/updateEvents", this.item.formLesson);
        }

        await this.$store.dispatch(
          "Grid/setDisciplines",
          this.item.disciplines
        );

        if (this.item.postponements && this.item.postponements.length > 0) {
          await this.$store.dispatch("Grid/updateRangeDate", {
            start:
              this.item.postponements[this.item.postponements.length - 1].date,
            end: "",
          });
        }

        this.tableDisciplines = this.item.disciplines;

        // let totalPrevisto = 0;

        // this.tableDisciplines.forEach((element) => {
        //   totalPrevisto += element.workload;
        // });
      });
  },
  computed: {
    ...mapState("Grid", ["discipline", "days", "event"]),
  },
  watch: {
    days: function (days) {
      this.formLesson.days = [];

      days.forEach((day, index) => {
        this.formLesson.days.push({
          date: day,
          number: index + 1,
          start: this.item.start_time,
          end: this.item.end_time,
          category: this.optionsGridLessonCategory[0],
        });
      });
    },
    lessons: function (value) {
      console.log(value);
    },
    discipline: function (value) {
      this.disciplineSelected = value;
      this.fetchTeachers(this.disciplineSelected.code);
    },
    isEventHandlerSidebarActive: function () {
      this.updateDays();
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";

.modal-new-lesson {
  .modal-dialog {
    max-width: 590px;
  }
}

.area-lessons {
  min-height: 400px;
}

.card-infos {
  .card {
    margin-bottom: 0px;

    .card-header {
      display: flex;
    }
  }
}
</style>
